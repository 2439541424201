<template>
  <div class="internal-transfer-vue">
    <Header class="responsive-bar">
      <template v-slot:title>
        {{ $t("internal-transfer-2f9b036cc57f3d906425010f8e828fb5") }}
      </template>
    </Header>
    <div>
      <div
        class="bg-white shadow rounded-md min-h-screen max-w-3xl m-auto sm:mt-5"
      >
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(requestInternalTransfer)">
            <div class="mb-6">
              <div>
                <div class="flex justify-between">
                  <h3 class="text-lg leading-6 font-normal text-gray-900">
                    {{
                      $t("internal-transfer-2f9b036cc57f3d906425010f8e828fb5")
                    }}
                  </h3>
                </div>
                <div
                  v-if="
                    $store.getters.get_profile.state !== 'Pending' &&
                    $store.getters.get_profile.state !== 'Review'
                  "
                >
                  <p
                    class="mt-1 max-w-2xl text-sm leading-5 font-light text-gray-500"
                  >
                    {{
                      $t(
                        "you-can-transfer-funds-between-the-trading-accounts-you-have-with-us"
                      )
                    }}
                  </p>
                  <p
                    class="mt-1 max-w-2xl text-sm leading-5 font-light text-gray-500"
                  >
                    {{
                      $t(
                        "please-enter-the-desired-transfer-amount-and-select-the-source-and-destination-accounts"
                      )
                    }}
                  </p>
                  <p
                    class="mt-1 max-w-2xl text-sm leading-5 font-light text-gray-500"
                  >
                    {{ $t("internal-transfers-are-processed-immediately") }}
                  </p>
                </div>
              </div>
            </div>

            <!--            Message when profile isn't complete -->
            <complete-id-identification
              v-if="$store.getters.get_profile.state !== 'Full'"
              title="before-you-are-able-to-transfer-funds-you-must-complete-id-verification-process"
            ></complete-id-identification>

            <div v-else class="align-middle inline-block w-full">
              <need-trading-account-alert
                v-if="$store.getters.get_live_accounts.length < 2"
                :title="
                  $t(
                    'You-need-to-have-at-least-two-trading-accounts-in-order-to-make-Internal-transfer'
                  )
                "
                class="mb-6"
              >
              </need-trading-account-alert>
              <div v-else>
                <div class="sm:border-b py-2">
                  <div class="sm:grid sm:grid-cols-9 sm:gap-4">
                    <div class="sm:col-span-4">
                      <div class="sm:mt-0 sm:grid sm:py-2">
                        <dt class="text-sm leading-5 font-normal mb-2">
                          {{ $t("from-account") }}
                        </dt>
                        <dd
                          class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                        >
                          <div class="space-y-1">
                            <div v-click-outside="hideFrom" class="relative">
                              <span
                                class="inline-block w-full rounded-md shadow-sm"
                                @click="showFromMenu = !showFromMenu"
                              >
                                <button
                                  aria-expanded="true"
                                  aria-haspopup="listbox"
                                  aria-labelledby="listbox-label"
                                  class="cursor-pointer h-16 relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                                  type="button"
                                >
                                  <span
                                    v-if="Object.keys(from).length !== 0"
                                    class="w-full truncate"
                                  >
                                    <span class="truncate">
                                      {{ from.backend.type }} -
                                      {{ from.external_id }}
                                    </span>
                                    <br />
                                    <span class="truncate text-gray-500">
                                      {{
                                        new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: from.currency,
                                        }).format(
                                          from.balance.WithdrawableAmount
                                        )
                                      }}
                                    </span>
                                  </span>
                                  <span
                                    v-else
                                    class="w-full inline-flex space-x-2 truncate"
                                  >
                                    {{ $t("please-select-account") }}
                                  </span>
                                  <svg
                                    v-if="Object.keys(from).length !== 0"
                                    class="absolute z-10 w-6 text-gray-500 inset-y-0 right-7 top-6 flex items-center pr-2 hover:text-gray-400"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    @click="from = {}"
                                  >
                                    <path
                                      d="M6 18L18 6M6 6l12 12"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                    />
                                  </svg>
                                  <span
                                    class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                                  >
                                    <svg
                                      class="h-5 w-5 text-gray-400"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 20 20"
                                    >
                                      <path
                                        d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="1.5"
                                      />
                                    </svg>
                                  </span>
                                </button>
                              </span>
                              <div
                                v-if="showFromMenu"
                                class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10"
                              >
                                <ul
                                  aria-labelledby="listbox-label"
                                  class="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                                  role="listbox"
                                  tabindex="-1"
                                >
                                  <li
                                    v-for="(
                                      account, index
                                    ) in from_account_data"
                                    id="listbox-item-022"
                                    :key="index"
                                    class="text-gray-900 hover:bg-gray-100 cursor-pointer select-none relative py-2 pl-3 pr-9"
                                    role="option"
                                  >
                                    <span
                                      class="flex w-full"
                                      @click="
                                        (from = account),
                                          (showFromMenu = false),
                                          (max_amount =
                                            account.balance.WithdrawableAmount)
                                      "
                                    >
                                      <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                                      <span
                                        v-if="
                                          (whitelabel === 'ICMEU' ||
                                            whitelabel === 'ICMVC' ||
                                            whitelabel === 'ICMCapital' ||
                                            whitelabel === 'ICMMENA' ||
                                            whitelabel === 'GCCBrokers' ||
                                            whitelabel === 'ICMAP' ||
                                            whitelabel === 'ICMMU' ||
                                            whitelabel === 'ICMEU' ||
                                            whitelabel === 'ICMTrader' ||
                                            whitelabel === 'TradiNext') &&
                                          account.backend.name.endsWith('IB')
                                        "
                                        class="truncate mr-3"
                                      >
                                        Wallet - {{ account.external_id }}
                                      </span>
                                      <span v-else class="truncate mr-3">
                                        {{ account.backend.type }} -
                                        {{ account.external_id }}
                                      </span>
                                      <br />
                                      <span class="truncate text-gray-500">
                                        {{
                                          new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: account.currency,
                                          }).format(
                                            account.balance.WithdrawableAmount
                                          )
                                        }}
                                      </span>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </dd>
                      </div>
                    </div>
                    <div class="sm: pt-6 arrow-rotate">
                      <svg
                        class="h-full m-auto rotate-180"
                        fill="none"
                        height="22"
                        viewBox="0 0 22 22"
                        width="22"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.1111 7.66667L15.4444 11L12.1111 7.66667ZM15.4444 11L12.1111 14.3333L15.4444 11ZM15.4444 11H6.55556H15.4444ZM21 11C21 12.3132 20.7413 13.6136 20.2388 14.8268C19.7363 16.0401 18.9997 17.1425 18.0711 18.0711C17.1425 18.9997 16.0401 19.7363 14.8268 20.2388C13.6136 20.7413 12.3132 21 11 21C9.68678 21 8.38642 20.7413 7.17317 20.2388C5.95991 19.7363 4.85752 18.9997 3.92893 18.0711C3.00035 17.1425 2.26375 16.0401 1.7612 14.8268C1.25866 13.6136 1 12.3132 1 11C1 8.34784 2.05357 5.8043 3.92893 3.92893C5.8043 2.05357 8.34784 1 11 1C13.6522 1 16.1957 2.05357 18.0711 3.92893C19.9464 5.8043 21 8.34784 21 11Z"
                          stroke="#00B8EE"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </svg>
                    </div>
                    <div class="sm:col-span-4">
                      <div class="sm:mt-0 sm:grid sm:py-2">
                        <dt class="text-sm leading-5 font-normal mb-2">
                          {{ $t("to-account") }}
                        </dt>
                        <dd
                          class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                        >
                          <div class="space-y-1">
                            <div v-click-outside="hideTo" class="relative">
                              <span
                                class="inline-block w-full rounded-md shadow-sm"
                                @click="showToMenu = !showToMenu"
                              >
                                <button
                                  aria-expanded="true"
                                  aria-haspopup="listbox"
                                  aria-labelledby="listbox-label"
                                  class="cursor-pointer h-16 relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                                  type="button"
                                >
                                  <span
                                    v-if="Object.keys(to).length !== 0"
                                    class="w-full truncate"
                                  >
                                    <span class="truncate">
                                      {{ to.backend.type }} -
                                      {{ to.external_id }}
                                    </span>
                                    <br />
                                    <span class="truncate text-gray-500">
                                      {{
                                        new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: to.currency,
                                        }).format(to.balance.WithdrawableAmount)
                                      }}
                                    </span>
                                  </span>
                                  <span
                                    v-else
                                    class="w-full inline-flex space-x-2 truncate"
                                  >
                                    {{ $t("please-select-account") }}
                                  </span>
                                  <svg
                                    v-if="Object.keys(to).length !== 0"
                                    class="absolute z-10 w-6 text-gray-500 inset-y-0 right-7 top-6 flex items-center pr-2 hover:text-gray-400"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    @click="to = {}"
                                  >
                                    <path
                                      d="M6 18L18 6M6 6l12 12"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                    />
                                  </svg>
                                  <span
                                    class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                                  >
                                    <svg
                                      class="h-5 w-5 text-gray-400"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 20 20"
                                    >
                                      <path
                                        d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="1.5"
                                      />
                                    </svg>
                                  </span>
                                </button>
                              </span>
                              <div
                                v-if="showToMenu"
                                class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10"
                              >
                                <ul
                                  aria-labelledby="listbox-label"
                                  class="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                                  role="listbox"
                                  tabindex="-1"
                                >
                                  <li
                                    v-for="(account, index) in to_account_data"
                                    id="listbox-item-0"
                                    :key="index"
                                    class="text-gray-900 hover:bg-gray-100 cursor-pointer select-none relative py-2 pl-3 pr-9"
                                    role="option"
                                  >
                                    <span
                                      class="flex w-full"
                                      @click="
                                        (to = account), (showToMenu = false)
                                      "
                                    >
                                      <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                                      <span
                                        v-if="
                                          (whitelabel === 'ICMEU' ||
                                            whitelabel === 'ICMVC' ||
                                            whitelabel === 'ICMCapital' ||
                                            whitelabel === 'ICMMENA' ||
                                            whitelabel === 'GCCBrokers' ||
                                            whitelabel === 'ICMAP' ||
                                            whitelabel === 'ICMMU' ||
                                            whitelabel === 'ICMEU' ||
                                            whitelabel === 'ICMTrader' ||
                                            whitelabel === 'TradiNext') &&
                                          account.backend.name.endsWith('IB')
                                        "
                                        class="truncate mr-3"
                                      >
                                        Wallet - {{ account.external_id }}
                                      </span>
                                      <span v-else class="truncate mr-3">
                                        {{ account.backend.type }} -
                                        {{ account.external_id }}
                                      </span>
                                      <br />
                                      <span class="truncate text-gray-500">
                                        {{
                                          new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: account.currency,
                                          }).format(
                                            account.balance.WithdrawableAmount
                                          )
                                        }}
                                      </span>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </dd>
                      </div>
                    </div>
                  </div>
                  <span class="text-sm text-red-600 mb-2">{{ error }}</span>
                </div>
                <div class="sm:border-b border-gray-200">
                  <div
                    class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                  >
                    <dt class="text-sm leading-5 font-normal">
                      {{ $t("enter-amount") }}
                    </dt>
                    <dd
                      class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                      <div class="sm:col-span-2">
                        <!-- Number field that will hold "amount" value
                            This field will be shown every time user types
                            and hidden every time he clicks away -->
                        <ValidationProvider
                          v-slot="{ errors }"
                          :name="$t('please-input-a-number-greater-than-zero')"
                          :rules="`required|min_value:1|max_value:${max_amount}`"
                        >
                          <div class="mt-1 relative rounded-md shadow-sm input">
                            <div
                              class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                            >
                              <span
                                v-if="
                                  Object.keys(from).length > 0 ||
                                  Object.keys(to).length > 0
                                "
                                class="text-gray-300 sm:text-md sm:leading-5"
                                v-html="sanitizeSymbol(from.currency)"
                              >
                              </span>
                            </div>

                            <input
                              id="amount"
                              v-model="amount"
                              aria-describedby="price-currency"
                              class="form-input h-12 font-light block w-full pl-7 pr-3 sm:text-sm sm:leading-5"
                              step="any"
                              type="number"
                              @blur="onBlurNumber(errors)"
                            />
                          </div>
                          <span class="text-sm text-red-600 h-4">{{
                            errors[0]
                          }}</span>
                          <span
                            v-if="amountError"
                            class="text-sm text-red-600 h-4"
                            >{{ amountError }}</span
                          >
                          <span
                            v-if="currencyError.value"
                            class="text-sm text-red-600 h-4"
                            >{{ $t(currencyError.message) }}</span
                          >
                        </ValidationProvider>

                        <!-- Text field that will show "dotValue" 
                            which is equivalent of "amount" but with added dots on every 3 diggits.  
                            This field will be hidden every time user clicks on it
                            and will be shown every time user clicks away from input -->
                        <!--                        <ValidationProvider :name="$t('please-input-a-number-greater-than-zero')"-->
                        <!--                                            :rules="`required|min_value:1|max_value:${max_amount}`" v-slot="{ errors }"-->
                        <!--                                            v-else>-->
                        <!--                          <div class="mt-1 relative rounded-md shadow-sm input">-->
                        <!--                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">-->
                        <!--                              <span class="text-gray-300 sm:text-md sm:leading-5">-->
                        <!--                                $-->
                        <!--                              </span>-->
                        <!--                            </div>-->

                        <!--                            <input type="text"-->
                        <!--                                   id="amount1"-->
                        <!--                                   v-model="dotValue"-->
                        <!--                                   :disabled="$store.getters.get_live_accounts.length === 0"-->
                        <!--                                   class="form-input h-12 font-light block w-full pl-7 pr-3 sm:text-sm sm:leading-5" aria-describedby="price-currency"-->
                        <!--                                   @focus="onFocusText">-->
                        <!--                          </div>-->
                        <!--                          <span class="text-sm text-red-600 h-4">{{ inputNumberError[0] }}</span>-->
                        <!--                          <span class="text-sm text-red-600 h-4" v-if="amountError">{{ amountError }}</span>-->
                        <!--                        </ValidationProvider>-->
                      </div>
                    </dd>
                  </div>
                </div>
              </div>
              <div v-if="$store.getters.get_profile.state === 'Full'">
                <span
                  class="inline-flex rounded-md shadow-sm w-full sm:m-0 mt-6"
                >
                  <Button
                    :disabled="
                      isDisabled || $store.getters.get_live_accounts.length < 2
                    "
                    class="relative block w-full bg-main-button-color items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-indigo"
                    type="submit"
                  >
                    {{ $t("request-internal-transfer") }}
                  </Button>
                </span>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MoneyFormat from "vue-money-format";
import Header from "@/components/Header";
import ClickOutside from "vue-click-outside";
import CompleteIdIdentification from "@/components/CompleteIdIdentification";
import NeedTradingAccountAlert from "@/components/NeedTradingAccountAlert";

export default {
  name: "InternalTransfer",
  components: {
    "money-format": MoneyFormat,
    Header,
    CompleteIdIdentification,
    NeedTradingAccountAlert,
  },
  data() {
    return {
      whitelabel: process.env.VUE_APP_WHITELABEL,
      from: {},
      to: {},
      amount: "",
      isDisabled: false,
      error: null,
      amountError: null,
      max_amount: Infinity,
      selectedAccount: {},
      showFromMenu: false,
      showToMenu: false,
      inputNumberVisible: true,
      inputNumberError: "",
      dotValue: 0,
      currencyError: {
        value: false,
        message:
          "in-order-to-transfer-funds-between-different-currencies-please-call-customer-support",
      },
    };
  },
  computed: {
    ...mapGetters(["get_profile", "get_uploaded_documents"]),
    from_account_data() {
      let accounts = this.$store.getters.get_account_data
        .filter((account) => account.backend.mode === "Live")
        .map((item) => {
          return {
            ...item,
            display_name:
              item.backend.type +
              " - " +
              item.external_id +
              " " +
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: item.currency,
              }).format(item.balance.WithdrawableAmount),
          };
        })
        .filter((account) => account.id !== this.to.id);

      if (
        Object.keys(this.to).length > 0 &&
        process.env.VUE_APP_WHITELABEL === "ICMVC"
      ) {
        return accounts.filter(
          (account) => account.currency === this.to.currency
        );
      } else {
        return accounts;
      }
    },
    to_account_data() {
      let accounts = this.$store.getters.get_account_data
        .filter((account) => account.backend.mode === "Live")
        .map((item) => {
          return {
            ...item,
            display_name:
              item.backend.type +
              " - " +
              item.external_id +
              " " +
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: item.currency,
              }).format(item.balance.WithdrawableAmount),
          };
        })
        .filter((account) => account.id !== this.from.id);
      if (
        Object.keys(this.from).length > 0 &&
        process.env.VUE_APP_WHITELABEL === "ICMVC"
      ) {
        return accounts.filter(
          (account) => account.currency === this.from.currency
        );
      } else {
        return accounts;
      }
    },
  },
  created() {
    this.$store.dispatch("account_data");
    this.$store.dispatch("profile");
  },
  methods: {
    sanitizeSymbol(symbol) {
      return this.$sanitize(this.currencySymbol(symbol));
    },
    /*************************************************************
     CODE FOR "ENTER AMOUNT" INPUT
     onBlurNumber(e), onFocusText(), thousandSeprator(amount)
     when user insert value "amount" in input field  and clicks away
     we hide that form and show text input that will show input dotValue
     which is holding "amount" value but with added dots after each 3 diggits
     When user clicks on text field with dotValue, we hide it and show the original input
     so user can change his "amount" value
     ****************************************************************/
    onBlurNumber(errors) {
      if (
        this.amount !== "" &&
        this.amount !== undefined &&
        amount !== 0 &&
        this.amount !== null
      ) {
        this.inputNumberVisible = false;
        this.dotValue = this.thousandSeprator(this.amount);
        this.inputNumberError = errors;
      }
    },
    onFocusText() {
      this.inputNumberVisible = true;
    },
    thousandSeprator(amount) {
      if (this.to_account_data[0].currency === "USD") {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
    requestInternalTransfer() {
      this.currencyError.value = false;
      if (
        Object.keys(this.to).length !== 0 &&
        Object.keys(this.from).length !== 0
      ) {
        if (this.amount <= this.from.balance.WithdrawableAmount) {
          if (this.from.currency === this.to.currency) {
            this.isDisabled = true;
            let data = {
              amount: this.amount,
              wallet_from: this.from.id,
              wallet_to: this.to.id,
            };
            this.$store
              .dispatch("internal_transfer", data)
              .then(() => {
                this.$notify({
                  group: "foo",
                  text: `${this.$t("your-request-is-successfully-processed")}`,
                });
                this.isDisabled = false;
                this.$router.replace("/payment-history");
              })
              .catch((err) => {
                this.isDisabled = false;
                this.$notify({
                  group: "foo",
                  text: `${Object.values(err.data)[0]}`,
                  type: "warn",
                });
              });
          } else {
            this.currencyError.value = true;
          }
        }
      } else this.error = this.$t("please-select-account");
    },
    hideFrom() {
      this.showFromMenu = false;
    },
    hideTo() {
      this.showToMenu = false;
    },
    filterAccounts() {
      this.to_account_data = [];
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>