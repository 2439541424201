var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"rounded-md bg-yellow-50 sm:p-5 m-8"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-shrink-0"},[_c('svg',{staticClass:"h-5 w-5 text-yellow-400",attrs:{"fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"clip-rule":"evenodd","d":"M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z","fill-rule":"evenodd"}})])]),_c('div',{staticClass:"ml-3"},[_c('h3',{staticClass:"text-sm leading-5 font-normal text-yellow-800"},[_vm._v(" "+_vm._s(_vm.$t("attention-needed"))+" ")]),(
            (_vm.whitelabel === 'TD365' ||
              _vm.whitelabel === 'TDSouthAfrica' ||
              _vm.whitelabel === 'OneTradingMarkets' ||
              _vm.whitelabel === 'EagleGlobalMarkets' ||
              _vm.whitelabel === 'TradeCoreUK') &&
            (_vm.$store.getters.get_user_data.profile_state === 99 ||
              _vm.$store.getters.get_user_data.profile_state === null)
          )?_c('div',{staticClass:"mt-2 text-sm leading-5 text-yellow-700"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( "please-wait-while-we-review-your-application-if-it-takes-more-than--5b1585b89429ea2e73b4b5fc85ce86b1" ))+" ")]),_c('a',{staticClass:"text-indigo-600 cursor-pointer",on:{"click":_vm.checkProfileState}},[(_vm.whitelabel === 'OneTradingMarkets')?_c('span',[_vm._v(_vm._s(_vm.$t("refresh"))+" ")]):_c('span',[_vm._v("Refresh")])])]):_c('div',[(
              _vm.$store.getters.get_profile.state === 'Review' &&
              _vm.whitelabel !== 'TD365'
            )?_c('div',{staticClass:"mt-2 text-sm leading-5 text-yellow-700"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( "your-application-is-pending-approval-please-upload-required-documents" ))+" ")]),_c('router-link',{attrs:{"to":"/upload-documents"}},[_c('a',{staticClass:"text-indigo-600"},[_vm._v(_vm._s(_vm.$t("click-here-to-upload")))])])],1):_c('div',{staticClass:"mt-2 text-sm leading-5 text-yellow-700"},[_c('p',[(_vm.whitelabel === 'TDSouthAfrica')?_c('span',[_vm._v(" We need more information to complete your application. We’ve sent you an email with the details. ")]):_vm._e(),(_vm.whitelabel === 'TD365')?_c('span',[(_vm.title)?_c('span',[_vm._v(" Your application is under review, you will be contacted as soon as it has been processed. ")]):_vm._e()]):[(_vm.title)?_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t( "further-id-verification-required-to-progress-account-application" ))+" ")]),_c('router-link',{attrs:{"to":"/upload-documents"}},[_c('a',{staticClass:"text-indigo-600"},[_vm._v(_vm._s(_vm.$t("click-here-to-upload")))])])]],2)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }